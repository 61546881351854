



































































import { Component, PropSync, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import NavigationDrawerModule from "@/store/modules/navigationDrawer.module";
import { DrawerEmployee } from "@/models/utils/c-drawer.interace";
const navigationDrawerModule = getModule(NavigationDrawerModule, store);

@Component
export default class CosmopolDrawer extends Vue {
  @PropSync("drawer", { required: true, default: false, type: Boolean })
  private _drawer: boolean;

  private top = 0;

  get employee(): DrawerEmployee {
    return navigationDrawerModule.employee;
  }

  @Watch("_drawer")
  private onShowChanged(show: boolean): void {
    if (show) this.top = document.documentElement.scrollTop;
  }

  private onInput(val: boolean): void {
    this._drawer = val;
    if (val === false) {
      navigationDrawerModule.setSelectedEmployee(null as DrawerEmployee);
    }
    navigationDrawerModule.setDrawerState(val);
  }
}
